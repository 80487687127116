<template>
  <a-select
    v-model="model"
    v-bind="parsedAttrs"
    v-on="parsedListeners"
    :disabled="disabled"
    @popupScroll="scroll"
    class="app-select"
    :class="{
      'app-select--error': error,
      'app-select--disabled': disabled,
      ['app-select--theme-' + theme]: theme,
      ['app-select--size-' + size]: size,
      'app-select--text': text
    }"
    :not-found-content="loading ? undefined : getTranslationOrKey('components.common.noData')"
  >
    <template v-for="(item, id) in items">
      <slot
        :item="item"
        :id="id"
      >
        <a-select-option
          v-if="skipItemFilter(item)"
          :value="item[itemValue]"
          :key="item[itemValue]"
        >
          <slot
            v-if="Array.isArray(itemName)"
            name="item-name"
            :item="item"
          >
            <span v-for="name in itemName">{{ getTranslationOrKey(item[name]) }} </span>
          </slot>
          <slot
            v-else-if="typeof itemName === 'function'"
            name="item-name"
            :item="item"
          >
            {{ getTranslationOrKey(itemName(item)) }}
          </slot>
          <slot
            v-else
            name="item-name"
            :item="item"
          >
            {{ getTranslationOrKey(item[itemName]) }}
          </slot>
        </a-select-option>
      </slot>
    </template>
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
  </a-select>
</template>
<script>

import {translationExists} from "../../mixins/local/translationExists.mixin";

export default {
    name: "AppSelect",
    model: {
        event: "input",
        prop: "value"
    },
    props: {
        value: {
            type: [String, Number, Array],
            default: ""
        },
        items: {
            type: [Array, Object],
            required: true
        },
        itemValue: {
            type: String,
            default: "id"
        },
        itemName: {
            type: [String, Array, Function],
            default: "title"
        },
        error: Boolean,
        theme: String,
        disabled: Boolean,
        defaultValue: [String, Number],
        size: String,
        translateItems: {
            type: Boolean,
            default: false
        },
        title: {
            type: [Boolean, String],
            default: false
        },
        text: {
            type: [Boolean, String],
            default: false
        },
        skippedItem: {
            type: [Boolean, String, Number],
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
        model: {
            get() {
                if(this.title) return this.title;
                return this.value;
            },
            set(val) {
                this.$emit("input", val === null || val === undefined ? this.defaultValue : val);
            }
        }
    },
    mixins: [translationExists],
    methods: {
        scroll(e) {
            const {target} = e;
            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                this.$emit("scrollend", e);
            }
        },
        skipItemFilter(item) {
            if(!this.skippedItem) return true;
            return item[this.itemValue] !== this.skippedItem;
        }
    }
};
</script>
<style lang="scss">
.app-select {
    .ant-select-selection__clear {
        width: 24px;
        height: 24px;
        margin-top: -12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
            display: block;
        }
    }

    .ant-select-selection {
        border: 1px solid var(--color-border);
    }

    .ant-select-selection--single::before {
        right: 13px !important;
        background-image: url('../../assets/svg/chevron-down.svg');
    }

    &--error {
        .ant-select-selection {
            border: 1px solid #e55353;
            animation: error 0.5s 1 both;
        }
    }
    &--text {
        .ant-select-selection {
            background: transparent;
            border: transparent;
            box-shadow: none;
            &-selected-value {
                margin-right: 15px;
            }
        }
    }

    .ant-select-selection--single::before {
        top: 50%;
        margin-top: -10px;
        right: 10px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
        display: block;
    }

    &--theme-transparent {
        .ant-select-selection {
            border: 0;
            box-shadow: none;
        }
    }

    &--disabled .ant-select-selection {
        border: 1px solid var(--color-border) !important;
        background: var(--color-border-lighten) !important;

        &:before {
            display: none;
        }
    }

    &--theme-application {
        .ant-select-selection__rendered {
            height: 48px;
            margin-left: 13px;
            display: flex;
            align-items: center;
        }

        .ant-select-selection {
            background: #EFEFEF;
            border: 1px solid #E1E1E1;
            border-radius: 10px;
            height: 48px;
        }
    }

    &--theme-gray {
        .ant-select-selection {
            background: #EFEFEF;
            border: 1px solid #E1E1E1;
        }
    }

    &--size-medium {
        .ant-select-selection__rendered {
            height: 48px;
            margin-left: 13px;
            display: flex;
            align-items: center;
        }

        .ant-select-selection {
            border-radius: 10px;
            height: 48px;
        }
    }
    &--size-large {

    }
}
</style>
