var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-select',_vm._g(_vm._b({staticClass:"app-select",class:{
    'app-select--error': _vm.error,
    'app-select--disabled': _vm.disabled,
    ['app-select--theme-' + _vm.theme]: _vm.theme,
    ['app-select--size-' + _vm.size]: _vm.size,
    'app-select--text': _vm.text
  },attrs:{"disabled":_vm.disabled,"not-found-content":_vm.loading ? undefined : _vm.getTranslationOrKey('components.common.noData')},on:{"popupScroll":_vm.scroll},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'a-select',_vm.parsedAttrs,false),_vm.parsedListeners),[_vm._l((_vm.items),function(item,id){return [_vm._t("default",function(){return [(_vm.skipItemFilter(item))?_c('a-select-option',{key:item[_vm.itemValue],attrs:{"value":item[_vm.itemValue]}},[(Array.isArray(_vm.itemName))?_vm._t("item-name",function(){return _vm._l((_vm.itemName),function(name){return _c('span',[_vm._v(_vm._s(_vm.getTranslationOrKey(item[name]))+" ")])})},{"item":item}):(typeof _vm.itemName === 'function')?_vm._t("item-name",function(){return [_vm._v(" "+_vm._s(_vm.getTranslationOrKey(_vm.itemName(item)))+" ")]},{"item":item}):_vm._t("item-name",function(){return [_vm._v(" "+_vm._s(_vm.getTranslationOrKey(item[_vm.itemName]))+" ")]},{"item":item})],2):_vm._e()]},{"item":item,"id":id})]}),(_vm.loading)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }