<template>
    <a-textarea :class="[$style.textarea, {
        [$style['textarea--error']]: error,
        [$style['textarea--disabled']]: disabled,
        [$style['textarea--size-' + size]]: size,
    }]" :value="value" v-bind="parsedAttrs" v-on="parsedListeners" :disabled="disabled" :autoSize="false" ref="input" @input="onInput" :style="{height: height + 'px'}"/>
</template>
<script>
export default {
    name: 'AppTextarea',
    props: {
        error: Boolean,
        disabled: Boolean,
        size: String,

        value: {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {
            height: 0
        }
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
    },
    mounted() {
        this.setHeight();
    },
    watch: {
        value: 'setHeight'
    },
    methods: {
        focus() {
            this.$refs.input.$el.focus();
        },
        setHeight() {
            if(this.$refs.input) {
                this.height = 0;
                this.$nextTick(() => {
                    this.height = Math.min(this.$refs.input.$el.scrollHeight, 248);
                })
            }
        },
        onInput(e) {
            // this.setHeight();
            this.$emit('input', e.target.value)
        }
    }
}
</script>
<style lang="scss" module>
@import '../../assets/scss/animation.scss';
textarea.textarea {
    height: min-content;
    font-size: 16px;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    color: var(--shadow-avatar-04);
    line-height: 1.5;
    min-height: 56px;
    resize: none;
    border-color: var(--color-border);
    padding-top: 12px;
    padding-bottom: 12px;
    &--error {
        border-color: var(--color-danger-dark);
        animation: error 0.5s 1 both;
    }
    &--disabled {
        border-color: var(--color-border) !important;
        background: var(--color-border-lighten) !important;
    }
    &--size-extra-large {
        min-height: 48px;
    }
}
</style>
